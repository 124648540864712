
import { defineComponent, ref, onMounted, reactive, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { UploadOutlined } from '@ant-design/icons-vue';
import { Moment } from 'moment';
import UploadDrawer from '@/views/Checking/components/UploadDrawer.vue';
import { ProgramType } from '@/views/Delay/type';
import {
    batchSave,
    findByGroupCode,
    getAllDealerInfos,
} from '@/API/checking/exemptionSetup';
import { getUser, getProgramCodeList } from '@/API/checking/checkingManagement';
import {
    getRegionApi,
    getDealerGroup,
    getOfferType,
    getCustomerType,
} from '@/API/claimDelay/common';
import { message } from 'ant-design-vue';

interface DataInterface {
    amountWithoutVat: string;
    bu: string | undefined;
    buId?: number | undefined;
    documentId?: string;
    eligibleDealerId?: string | undefined;
    eligibleDealerKey: number | undefined;
    eligibleDealerNameCn?: string;
    eligibleDealerNameEn?: string;
    eligibleDealerValue: string | undefined;
    eligibleProgramId?: string | undefined;
    eligibleProgramKey: number | undefined;
    eligibleProgramNameCn?: string;
    eligibleProgramNameEn?: string;
    eligibleProgramValue: string | undefined;
    exemptionCode?: string | undefined;
    exemptionName?: string | undefined;
    exemptionPeriodEnd?: string;
    exemptionPeriodStart?: string;
    exemptionReason: string | undefined;
    fileName?: string;
    filePath?: string;
    fileType?: string;
    vinCount: string;
    period: Moment[];
    requestDate?: string | undefined;
    endDate?: string | undefined;
}

export default defineComponent({
    components: {
        UploadOutlined,
        UploadDrawer,
    },
    setup() {
        const columns = [
            {
                title: 'Eligible Dealer',
                width: 420,
                slots: { customRender: 'dealer' },
            },
            {
                title: 'Eligible Program',
                width: 420,
                slots: { customRender: 'program' },
            },
            {
                width: 150,
                slots: { title: 'buTitle', customRender: 'bu' },
            },
            {
                title: 'VIN Count',
                width: 150,
                slots: { customRender: 'vinCount' },
            },
            {
                title: 'Amount(W/O VAT)',
                width: 150,
                slots: { customRender: 'amountWithoutVat' },
            },
            {
                width: 200,
                slots: { title: 'periodTitle', customRender: 'period' },
            },
            {
                width: 150,
                slots: {
                    title: 'reasonTitle',
                    customRender: 'exemptionReason',
                },
            },
            {
                title: 'Operation',
                width: 150,
                slots: { customRender: 'operation' },
            },
        ];
        const data = ref<DataInterface[]>([
            {
                eligibleDealerKey: undefined,
                eligibleDealerValue: undefined,
                eligibleProgramKey: undefined,
                eligibleProgramValue: undefined,
                bu: undefined,
                vinCount: '',
                amountWithoutVat: '',
                period: [],
                exemptionReason: undefined,
            },
        ]);

        const exemptionName = ref('');
        const requestDate = ref<Moment>();
        const endDate = ref<Moment>();

        // 表格需要人为选择的数据获取
        const dealerOptions = ref([]);
        const getDealerOptions = () => {
            findByGroupCode({ groupCode: 'eligible_dealer' }).then(
                (res: any) => {
                    dealerOptions.value = res.filter((r: any) => {
                        return r.itemCode !== 0;
                    });
                }
            );
        };
        const selectDealer = (value: string, option: any, record: any) => {
            record.eligibleDealerId = option.currentItem.id;
        };

        const selectDealerCode = (value: string, option: any, record: any) => {
            record.eligibleDealerNameCn = option.currentItem.nameCn;
            record.eligibleDealerNameEn = option.currentItem.nameEn;
        };

        const selectDealerGroup = (value: string, option: any, record: any) => {
            record.eligibleDealerNameCn = option.currentItem.dealerGroupCn;
            record.eligibleDealerNameEn = option.currentItem.dealerGroupEn;
        };
        const selectDealerRegion = (
            value: string,
            option: any,
            record: any
        ) => {
            record.eligibleDealerNameCn = option.currentItem.subRegionCn;
            record.eligibleDealerNameEn = option.currentItem.subRegionEn;
        };

        const programOptions = ref([]);
        const getProgramOptions = () => {
            findByGroupCode({ groupCode: 'eligible_program' }).then(
                (res: any) => {
                    programOptions.value = res.filter((r: any) => {
                        return r.itemCode !== 0;
                    });
                }
            );
        };
        const selectProgram = (value: string, option: any, record: any) => {
            record.eligibleProgramId = option.currentItem.id;
        };
        const selectProgramCategory = (
            value: string,
            option: any,
            record: any
        ) => {
            record.eligibleProgramNameCn = option.currentItem.nameCn;
            record.eligibleProgramNameEn = option.currentItem.nameEn;
        };
        const selectCustomerType = (
            value: string,
            option: any,
            record: any
        ) => {
            record.eligibleProgramNameCn = option.currentItem.nameCn;
            record.eligibleProgramNameEn = option.currentItem.name;
        };

        const buList = ref([]);
        const selectBu = (value: string, option: any, record: any) => {
            record.buId = option.currentItem.id;
        };

        const reasonList = ref([]);
        const getReasonList = () => {
            findByGroupCode({ groupCode: 'exemption_reason' }).then(
                (res: any) => {
                    reasonList.value = res;
                }
            );
        };
        getDealerOptions();
        getProgramOptions();
        getReasonList();

        //? Eiligble Dealer 数据获取 code group region
        const dealerGroupList = ref([]);
        const genDealerGroup = () => {
            getDealerGroup().then((res: any) => {
                dealerGroupList.value = res;
            });
        };
        genDealerGroup();

        const regionMapList = reactive({});
        const genRegionList = (bu: string) => {
            if (bu in regionMapList) return;
            getRegionApi({ bu }).then((res: any) => {
                regionMapList[bu] = res;
            });
        };
        const getRegionByBu = (record: any) => {
            if (record.eligibleDealerKey === 3)
                record.eligibleDealerValue = undefined;
            genRegionList(record.bu);
        };

        const cleanDealerValue = (record: any) => {
            record.eligibleDealerValue = undefined;
        };

        //? Eiligble Program 数据获取 code category custom
        const programCodeList = ref<string[]>([]);
        let timeout: any;
        const searchProgramCode = (val: string) => {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            const params = {
                code: val,
            };
            function getData() {
                getProgramCodeList(params).then((res: any) => {
                    programCodeList.value = res;
                });
            }
            timeout = setTimeout(getData, 800);
        };

        const programTypeList = ref<ProgramType[]>([]);
        const genProgramTypeList = () => {
            getOfferType().then((res: any) => {
                const offerTypeList = res.incentiveCategory.reduce(
                    (totalList: any, arr: any) => {
                        return [...totalList, ...arr.offerType];
                    },
                    []
                );
                programTypeList.value = offerTypeList;
            });
        };
        genProgramTypeList();

        const customerTypeList = ref([]);
        const genCustomerTypeList = () => {
            getCustomerType().then((res: any) => {
                customerTypeList.value = res;
            });
        };
        genCustomerTypeList();

        const cleanProgramValue = (record: any) => {
            record.eligibleProgramValue = undefined;
        };

        // 上传drawer
        const uploadDrawerVisible = ref(false);
        const fileInfo = ref<any>(undefined);

        const openDrawer = () => {
            uploadDrawerVisible.value = true;
        };

        const closeDrawer = () => {
            uploadDrawerVisible.value = false;
        };

        const shareFileInfo = (file: any) => {
            fileInfo.value = file;
        };

        const addData = () => {
            data.value.push({
                eligibleDealerKey: undefined,
                eligibleDealerValue: undefined,
                eligibleProgramKey: undefined,
                eligibleProgramValue: undefined,
                bu: undefined,
                vinCount: '',
                amountWithoutVat: '',
                period: [],
                exemptionReason: undefined,
            });
        };

        const deleteRow = (record: any, index: number) => {
            data.value.splice(index, 1);
        };

        // 右下角Cancel
        const router = useRouter();
        const goBack = () => {
            router.push({
                name: 'exemption',
            });
        };
        // 右下角 Submit
        const submit = () => {
            let dataFlag = false;
            for (let i = 0; i < data.value.length; i++) {
                const item = data.value[i];
                if (
                    item.period.length === 0 ||
                    item.bu === undefined ||
                    item.exemptionReason === undefined
                ) {
                    dataFlag = true;
                    break;
                }
            }
            if (
                dataFlag ||
                exemptionName.value === '' ||
                requestDate.value === undefined ||
                endDate.value === undefined ||
                fileInfo.value === undefined
            ) {
                message.error('Please input all required field');
                return;
            }
            data.value.forEach((item: DataInterface) => {
                item.exemptionPeriodStart = item.period[0].format('MM/DD/YYYY');
                item.exemptionPeriodEnd = item.period[1].format('MM/DD/YYYY');
                item.documentId = fileInfo.value.documentId;
                item.fileName = fileInfo.value.fileName;
                item.filePath = fileInfo.value.filePath;
                item.fileType = fileInfo.value.fileType;
                item.endDate = endDate.value?.format('MM/DD/YYYY');
                item.requestDate = requestDate.value?.format('MM/DD/YYYY');
                item.exemptionName = exemptionName.value;
            });
            batchSave(data.value).then((res: any) => {
                if (res.code === '0') {
                    message.success('Submit Success!');
                }
            });
        };

        let allDealerInfos: any[];
        const dealerInfoList = ref<any[]>([]);
        getAllDealerInfos().then((res: any) => {
            allDealerInfos = res;
            dealerInfoList.value = res;
        });

        let timer: any;
        const fetching = ref(false);
        const filterDealerInfo = (val: string) => {
            fetching.value = true;
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => {
                dealerInfoList.value = allDealerInfos.filter((dealer: any) => {
                    return dealer.dealerCode.indexOf(val) > -1;
                });
                fetching.value = false;
            }, 300);
        };
        // dealer code 远程搜索防抖版

        const disabledDate = (current: Moment, type: string) => {
            if (
                type === 'request' &&
                endDate.value !== undefined &&
                endDate.value !== null
            ) {
                return current > endDate.value;
            }
            if (
                type === 'end' &&
                requestDate.value !== undefined &&
                requestDate.value !== null
            ) {
                return current < requestDate.value;
            }
            return false;
        };

        onMounted(() => {
            getUser().then((res) => {
                buList.value = res.buList
                    .filter((ele: any) => {
                        return ele.parentId === null;
                    })
                    .map((ele: any) => {
                        return { name: ele.nameCn, id: ele.id };
                    });
            });
        });

        onUnmounted(() => {
            clearTimeout(timer);
        });

        return {
            exemptionName,
            requestDate,
            endDate,
            columns,
            dealerOptions,
            selectDealer,
            selectDealerCode,
            selectDealerGroup,
            selectDealerRegion,
            programOptions,
            selectProgram,
            selectProgramCategory,
            selectCustomerType,
            buList,
            selectBu,
            reasonList,
            data,
            addData,
            deleteRow,
            uploadDrawerVisible,
            openDrawer,
            closeDrawer,
            shareFileInfo,
            goBack,
            submit,
            searchProgramCode,
            regionMapList,
            getRegionByBu,
            dealerGroupList,
            programCodeList,
            programTypeList,
            customerTypeList,
            cleanDealerValue,
            cleanProgramValue,
            filterDealerInfo,
            dealerInfoList,
            fetching,
            disabledDate,
        };
    },
});
