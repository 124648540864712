
import axios from 'axios';
import { defineComponent, ref, computed, watch } from 'vue';
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import { FileItem } from '@/API/types';
import { message } from 'ant-design-vue';

interface Doc {
    docName: string | null;
    docPath: string | null;
    docType: string | null;
    fileSize: number | null;
    id: string | null;
    is_watermark: string | null;
    serviceName: string | null;
    uploadTime: string | null;
    uploadUser: string | null;
    visibility: string | null;
}

export default defineComponent({
    components: {
        CheckCircleOutlined,
    },
    emits: ['close-upload-drawer', 'share-file-info'],
    props: {
        uploadDrawerVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isBeforeUpload = ref<boolean>(true);
        // 进度条
        const isShowUploadBtn = ref<boolean>(true);
        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0);
        const uploadFileSize = ref<string>('');
        const completeTips = ref<boolean>(false);
        // 上传功能出错后下载模态窗口
        // const loading = ref<boolean>(false);
        // const downloadModalVisible = ref<boolean>(false);
        // const downloadTipsText = ref<string>('');
        // const roundVal = ref();
        // 导入控制按钮
        const isImport = ref<boolean>(false);
        // 文件上传
        const isClickAbled = ref<boolean>(true);
        const uploadComplete = ref<boolean>(true);
        const formData = new FormData();
        const emptyVisible = ref<boolean>(false);
        const emptyTipsText = ref<string>('');

        // 设置抽屉显示状态
        const close = () => {
            emit('close-upload-drawer');
        };

        // 上传前
        const beforeUpload = (file: FileItem) => {
            const FILE_TYPE = ['application/pdf', 'image/jpeg'];
            const isSelectedType = FILE_TYPE.includes(file.type as string);
            if (!isSelectedType) {
                message.error('请上传PDF或JPG格式文件！');
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error('请上传不超过5M的文件！');
            }
            return isSelectedType && isLt5M;
        };

        const uploadRequest = (source: any) => {
            if (formData.has('document')) {
                formData.delete('document');
            }
            formData.append('document', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value =
                source.file.size / 1024 > 1024
                    ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
                    : `${Math.floor(source.file.size / 1024)} KB`;
            isBeforeUpload.value = false;
        };

        const fileInfo = ref({
            documentId: '',
            fileName: '',
            filePath: '',
            fileType: '',
        });

        // 上传文件
        const uploadFile = (source: any) => {
            isClickAbled.value = false;
            const url = '/docapi/upload';
            const params = {
                serviceName: 'oasis-claim-service',
            };
            axios({
                method: 'post',
                data: formData,
                url,
                params,
            })
                .then((res: any) => {
                    isShowUploadBtn.value = false;
                    curPercent.value = 100;
                    completeTips.value = true;
                    isClickAbled.value = true;
                    const data = res.data.data;
                    fileInfo.value.documentId = data.id;
                    fileInfo.value.fileName = data.docName;
                    fileInfo.value.filePath = data.docPath;
                    fileInfo.value.fileType = data.docType;
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        // 上传功能出错后下载模态窗口
        // const handleDownload = () => {
        //     loading.value = true;
        //     // 调下载错误文件接口
        //     const path = window.sessionStorage.getItem('errorFileName')
        //         ? window.sessionStorage.getItem('errorFileName')
        //         : null;
        //     if (!path) return;
        //     const downloadParams = {
        //         // payoutRoundId: roundVal.value,
        //         path: window.sessionStorage.getItem('errorFileName'),
        //     };
        //     const params = {
        //         url: `/claimapi/payout/reimbursement/download`,
        //         method: 'get',
        //         params: downloadParams,
        //         // fileName: `${+new Date()}.xlsx`,
        //     };
        //     downLoadTemplate(
        //         params,
        //         'application/vnd-excel; char-set=UTF-8'
        //     ).then((res) => {
        //         loading.value = false;
        //         downloadModalVisible.value = false;
        //         emptyVisible.value = false;
        //     });
        // };
        // const handleCancel = () => {
        //     downloadModalVisible.value = false;
        // };
        const showUploadHandle = () => {
            isBeforeUpload.value = true;
            isShowUploadBtn.value = true;
            curPercent.value = 0;
            completeTips.value = false;
            window.sessionStorage.removeItem('errorFileName');
            isImport.value = false;
        };

        const cancel = () => {
            close();
        };

        const confirm = () => {
            close();
            emit('share-file-info', fileInfo.value);
        };

        return {
            // loading,
            // downloadModalVisible,
            // downloadTipsText,
            // handleDownload,
            // handleCancel,
            // roundVal,
            close,
            uploadFile,
            beforeUpload,
            uploadComplete,
            isBeforeUpload,
            isShowUploadBtn,
            uploadRequest,
            curPercent,
            curUploadFile,
            uploadFileSize,
            isImport,
            showUploadHandle,
            completeTips,
            isClickAbled,
            emptyVisible,
            emptyTipsText,
            cancel,
            confirm,
        };
    },
});
